import React, { useEffect } from 'react'
import { globalHistory } from '@reach/router'

let contentClasses = 'bg-white z-20 w-full lg:w-3/5 py-16 lg:pt-32 lg:pb-0 relative'
let contentClassesSub = 'w-10/12 m-auto lg:py-24'

const Content = props => {
  useEffect(() => {
    const pathname = globalHistory.location.pathname.split('/')[1]
    if (pathname === 'assurance' || pathname === 'provisioning') {
      contentClasses = 'bg-white z-20 w-full h-full lg:pb-0 relative'
      contentClassesSub = 'w-10/12 m-auto'
    }
  })

  return (
    <div
      className={`${contentClasses} ${props.className}`}
    >
      <div className={contentClassesSub}>
        {props.children}
      </div>
    </div>
  )
}
export default Content
