import { CSSTransition } from 'react-transition-group'
import React from 'react'
import './Transitions.css'
const Fade = (props) => (<CSSTransition
  {...props}
  in
  classNames="Fade"
  timeout={500}
  appear
  exit
  enter
/>)
const FadeTop = (props) => (<CSSTransition
  {...props}
  in
  classNames="FadeTop"
  timeout={500}
  appear
  exit
  enter
/>)
const FadeLeft = (props) => (<CSSTransition
  {...props}
  in
  classNames="FadeLeft"
  timeout={500}
  exit
  enter
  appear
/>)
const FadeBottom = (props) => (<CSSTransition
  {...props}
  in
  classNames="FadeBottom"
  timeout={500}
  appear
  exit
  enter
/>)
const FadeRight = (props) => (<CSSTransition
  {...props}
  in
  classNames="FadeRight"
  timeout={500}
  appear
  exit
  enter
/>)

export { Fade, FadeLeft, FadeTop, FadeRight, FadeBottom }
