import React, { useEffect } from 'react'
import Counter from '../components/counter'
import { globalHistory } from '@reach/router'

let legendClasses = 'pl-0 m-0 list-none'
let legendClassesSub = 'mb-4'

const LegendItem = props => (
  <div
    className={`${props.className && props.className} font-bold flex flex-col`}
  >
    <strong className='text-4xl'>
      <Counter
        formatValue={n => parseFloat(n).toFixed(1)}
        value={props.value}
      />{' '}
      %
    </strong>
    <span className='font-bold text-lg'>{props.label}</span>
  </div>
)

const Legend = props => {
  useEffect(() => {
    const pathname = globalHistory.location.pathname.split('/')[1]
    if (pathname === 'assurance' || pathname === 'provisioning') {
      legendClasses = 'pl-0 m-0 list-none flex flex-wrap'
      legendClassesSub = 'mb-4 w-1/3'
    }
  })

  return (
    <ul className={legendClasses}>
      {props.series.map((item, index) => (
        <li key={'series_' + index} className={legendClassesSub}>
          <LegendItem
            label={item.label}
            value={item.value}
            className={item.className}
          />
        </li>
      ))}
    </ul>
  )
}
export { LegendItem, Legend }
