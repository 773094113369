import React, { useEffect } from 'react'
import { globalHistory } from '@reach/router'

let sectionClasses = 'w-full flex flex-col lg:flex-row'

const Section = props => {
  useEffect(() => {
    const pathname = globalHistory.location.pathname.split('/')[1]
    if (pathname === 'assurance' || pathname === 'provisioning') sectionClasses = 'h-screen'
  })

  return (
    <section
      id={props.id}
      className={`${sectionClasses} ${(props.className && props.className) || ''}`}
    >
      {props.children}
    </section>
  )
}

export default Section
