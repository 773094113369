import React from 'react'

const parseDate = date => {
  const newDate = new Date(date)
  const day = newDate.getDate()
  const month = newDate.getMonth() + 1
  const year = newDate.getFullYear()
  return `${day < 10 ? '0' + day : day}/${
    month < 10 ? '0' + month : month
  }/${year}`
}

const Quotes = props => (
  <div>
    {props.quotes && props.quotes.length > 0 && (
      <div className='flex justify-center mb-4 mt-4'>
        <button
          type='button'
          className='cursor-pointer text-2xl px-8 font-bold bg-transparent border-0 text-white transition lg:hover:text-secondary'
          onClick={props.onPrevQuote}
        >
          {'<'}
        </button>
        <div className='text-2xl'>
          {props.selected + 1}/{props.quotes.length}
        </div>
        <button
          type='button'
          className='cursor-pointer text-2xl px-8 font-bold bg-transparent border-0 text-white transition lg:hover:text-secondary'
          onClick={props.onNextQuote}
        >
          {'>'}
        </button>
      </div>
    )}
    {props.quotes[props.selected] && (
      <div>
        <blockquote className='p-0 mx-0 text-xl font-bold'>
          {props.quotes[props.selected].customer_comment || ''}
        </blockquote>
        <div className='text-right sm:pr-16 mt-10 font-bold'>
          <span className='uppercase text-lg mb-4 block'>
            {(props.quotes[props.selected].customer !== '-1') ? props.quotes[props.selected].customer : ''}
          </span>
          <span>{parseDate(props.quotes[props.selected].compile_at)}</span>
        </div>
      </div>
    )}
  </div>
)

export default Quotes
