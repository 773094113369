import React from 'react'
const StatText = props => (
  <h3
    className={`${
      props.className
    } block mt-4 mb-8 tracking-widest uppercase font-bold text-xl`}
  >
    {props.children}
  </h3>
)
export default StatText
